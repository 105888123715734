import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=a1d2b832"
import script from "./Footer.vue?vue&type=script&lang=js"
export * from "./Footer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomLink: require('/home/userapp/components/Atom/Link/Link.vue').default,AtomBrandLogoDesktop: require('/home/userapp/components/Atom/Brand/BrandLogoDesktop.vue').default,MoleculeLidFooterSocialMedia: require('/home/userapp/components/Molecule/Lid/FooterSocialMedia.vue').default})
