import { fallbackDistance } from '~/store/location-search'

const hitsPerPage = 25

export const state = () => ({
  // Search context
  departmentType: undefined,
  departmentVisibility: undefined,
  distance: fallbackDistance,
  location: '',
  page: 1,

  // Location search results
  city: undefined,
  errors: [],
  lat: undefined,
  lng: undefined,
  type: undefined,

  // Results
  departments: [],
  hits: [],
  totalHits: 0,
})

export const getters = {
  // Constants
  hitsPerPage: () => hitsPerPage,

  // Url parameters
  urlParameters: (state) => ({
    distance: state.distance === fallbackDistance ? undefined : state.distance,
    location: state.location ? state.location : undefined,
    page: state.page === 1 ? undefined : state.page,
  }),

  // Search context
  departmentType: (state) => state.departmentType,
  departmentVisibility: (state) => state.departmentVisibility,
  distance: (state) => state.distance,
  location: (state) => state.location,
  page: (state) => state.page,

  // Location-search results
  city: (state) => state.city,
  errors: (state) => state.errors,
  lat: (state) => state.lat,
  lng: (state) => state.lng,
  type: (state) => state.type,

  // Results
  departments: (state) => state.departments,
  hits: (state) => state.hits,
  totalHits: (state) => state.totalHits,
}

export const mutations = {
  // Initialize
  initialize: (state, { departmentType, departmentVisibility, distance, location, page }) => {
    state.departmentType = departmentType
    state.departmentVisibility = departmentVisibility
    state.distance = distance || fallbackDistance
    state.location = location || ''
    state.page = Math.max(1, page || 1)
  },

  // Search context
  setDistance(state, distance) {
    state.distance = distance || fallbackDistance
    this.dispatch('department-overview/fetch')
  },
  setLocation(state, location) {
    state.location = location || ''
    this.dispatch('department-overview/fetch')
  },
  setPage(state, page) {
    state.page = Math.max(1, page)
    this.dispatch('department-overview/fetch')
  },

  // Location search results
  setLocationSearchResults(state, { city, errors, lat, lng, type }) {
    state.city = city
    state.errors = errors
    state.lat = lat
    state.lng = lng
    state.type = type
  },

  // Results
  setDepartments: (state, departments) => (state.departments = departments),
  setHits: (state, hits) => (state.hits = hits),
  setTotalHits: (state, totalHits) => (state.totalHits = totalHits),
}

export const actions = {
  async fetch({ commit, dispatch, getters }) {
    const { departmentType, departmentVisibility, distance, location, page } = getters
    const { city, errors, lat, lng, status, type } = await dispatch('location-search/resolve', { distance, location }, { root: true })
    commit('setLocationSearchResults', { city, errors, lat, lng, type })
    if (status === 'error') {
      return
    }

    const key = `department-overview;departmentType:${departmentType};departmentVisibility:${departmentVisibility};lat:${lat};lng:${lng};distance:${distance}`
    const ttl = 300

    const fetcher = async () => {
      // Fetch 1024 results, therefore we will not need to fetch new results when we switch pages
      const { elasticSearch } = await this.$graphqlFetch({
        token: 'elasticsearch',
        query: `query findDepartments($departmentVisibility: [String], $departmentType: [String], $mapDistance: String, $orderBy: String) {
          elasticSearch(
            site: "default"
            section: "department"
            departmentVisibilityFacet: $departmentVisibility
            departmentTypeFacet: $departmentType
            orderBy: $orderBy
            mapDistance: $mapDistance
          ) {
            count
            hits (limit:1024) {
              slug
            }
          }
        }
      `,
        variables: {
          departmentVisibility,
          departmentType,
          orderBy: status === 'ok' ? `map POINT(${lng} ${lat}) ASC` : 'title ASC',
          mapDistance: status === 'ok' ? `POINT(${lng} ${lat}) ${distance}` : undefined,
        },
      })

      return {
        hits: elasticSearch.hits.map(({ slug }) => slug),
        totalHits: elasticSearch.count,
      }
    }

    // Fetch the hits
    const { hits, totalHits } = await dispatch('cache/fetch', { key, ttl, fetcher, fallback: { hits: [], totalHits: 0 } }, { root: true })
    commit('setHits', hits)
    commit('setTotalHits', totalHits)

    // Slice all associated entries within the current page
    const slice = hits.slice((page - 1) * hitsPerPage, page * hitsPerPage)
    const departments = await Promise.all(slice.map((slug) => dispatch('global/fetch', { slug }, { root: true })))
    commit(
      'setDepartments',
      departments.filter((department) => department)
    )
    return departments
  },
}
