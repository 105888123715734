import uniq from 'lodash/uniq'
import xor from 'lodash/xor'
import { distances } from '~/store/location-search'

const smallestDistance = distances[0]

export const state = () => ({
  // Search context
  distance: smallestDistance,
  location: '',

  // Location search results
  city: undefined,
  errors: [],
  lat: undefined,
  lng: undefined,
  type: undefined,

  // Selected collection districts
  selectedCollectionDistricts: [],

  // Results
  collectionDistricts: [],
  hits: [],
  totalHits: 0,
})

export const getters = {
  // Url parameters
  urlParameters: (state) => ({
    distance: state.distance === smallestDistance ? undefined : state.distance,
    location: state.location ? state.location : undefined,
  }),

  // Search context
  distance: (state) => state.distance,
  location: (state) => state.location,

  // Location-search results
  city: (state) => state.city,
  errors: (state) => state.errors,
  lat: (state) => state.lat,
  lng: (state) => state.lng,
  type: (state) => state.type,

  // Results
  collectionDistricts: (state) =>
    uniq([...state.collectionDistricts, ...state.selectedCollectionDistricts]).map((title) => ({ title, isSelected: state.selectedCollectionDistricts.includes(title) })),
  selectedCollectionDistricts: (state) => state.selectedCollectionDistricts,
  hits: (state) => state.hits,
  totalHits: (state) => state.totalHits,
}

export const mutations = {
  // Initialize
  initialize: (state, { distance, location }) => {
    state.distance = distance || smallestDistance
    state.location = location || ''
  },

  // Search context
  setDistance(state, distance) {
    state.distance = distance || smallestDistance
    this.dispatch('collection-page/fetch')
  },
  setLocation(state, location) {
    state.location = location || ''
    this.dispatch('collection-page/fetch')
  },

  // Location search results
  setLocationSearchResults(state, { city, errors, lat, lng, type }) {
    state.city = city
    state.errors = errors
    state.lat = lat
    state.lng = lng
    state.type = type
  },

  // Collection district context
  toggleCollectionDistrict(state, title) {
    state.selectedCollectionDistricts = xor(state.selectedCollectionDistricts, [title])
  },

  // Results
  setCollectionDistricts: (state, collectionDistricts) => (state.collectionDistricts = collectionDistricts),
  setHits: (state, hits) => (state.hits = hits),
  setTotalHits: (state, totalHits) => (state.totalHits = totalHits),
}

export const actions = {
  async fetch({ commit, dispatch, getters }) {
    const { distance, location } = getters
    const { city, errors, lat, lng, status, type } = await dispatch('location-search/resolve', { distance, location }, { root: true })
    commit('setLocationSearchResults', { city, errors, lat, lng, type })
    if (['error', 'none'].includes(status)) {
      return
    }

    const key = `department-overview;lat:${lat};lng:${lng};distance:${distance}`
    const ttl = 300

    const fetcher = async () => {
      const { elasticSearch } = await this.$graphqlFetch({
        token: 'elasticsearch',
        query: `query findCollectionDistricts($mapDistance: String, $orderBy: String) {
        elasticSearch(site: "default", section: "collectionDistrict", orderBy: $orderBy, mapDistance: $mapDistance) {
          hits (limit: 1024) {
            title
          }
        }
      }
    `,
        variables: {
          orderBy: status === 'ok' ? `map POINT(${lng} ${lat}) ASC` : 'title ASC',
          mapDistance: status === 'ok' ? `POINT(${lng} ${lat}) ${distance}` : undefined,
        },
      })

      return {
        collectionDistricts: elasticSearch.hits.map(({ title }) => title).sort(),
      }
    }

    // Fetch the hits
    const { collectionDistricts } = await dispatch('cache/fetch', { key, ttl, fetcher, fallback: { collectionDistricts: [] } }, { root: true })
    commit('setCollectionDistricts', collectionDistricts)
    return collectionDistricts
  },
}
