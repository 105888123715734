
import { defineComponent } from '@nuxtjs/composition-api'
import { getSiteConfig } from '@/assets/craft'

const statusCodeMap = { 404: 'Sorry, de pagina is niet beschikbaar', 500: 'Er is helaas iets misgegaan' }

export default defineComponent({
  layout: 'error',
  props: {
    error: { type: Object, required: false, default: undefined },
  },
  data: () => ({
    title: statusCodeMap[500],
    domain: null,
    introduction: '',
    description: '',
    image: undefined,
  }),
  async fetch() {
    const { domain } = getSiteConfig(this.$route)

    const page = await this.$store.dispatch('page/fetchError', {
      site: domain,
      sourceSection: this.error?.sourceSection || 'default',
      statusCode: this.error?.statusCode || 500,
    })

    this.domain = domain
    this.title = page?.title || this.error?.message || statusCodeMap[this.error?.statusCode] || statusCodeMap[500]
    this.introduction = page?.introduction || ''
    this.description = page?.description || ''
    this.image = page?.image || undefined
  },
})
